import React from "react";
import Nav from "../components/NewNav";
// import Contact from "../components/Contact";
import Steps  from "../components/Steps";
// import { Footer } from "../components/Footer";
import Offices from "../components/Offices";
import { FooterFixed } from "../components/FooterFixed";

export function Process() {
    return(
        <div>
        <Nav />
        <Steps />
        <Offices />
        {/* <Contact /> */}
        <FooterFixed />
        {/* <Footer /> */}
        </div>
    )
}