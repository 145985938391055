import React, { Component } from 'react';
import './style.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import ex1 from '../../assets/example1.png';
import ex2 from '../../assets/example2.png';
import ex3 from '../../assets/example3.png';
import ex1tall from '../../assets/example1tall.png';
import ex2tall from '../../assets/example2tall.png';
import ex3tall from '../../assets/example3tall.png';
import { Container } from '../Grid';

export default class Examples extends Component {
    

  render() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true
      };
    
        return (
            <div className="examples">
                <Container>
                <h1>Examples of Our Work</h1>
                <div className="horizSlide">
                <Slider {...settings}> 
                    <div className="slide-img"><img src={ex1} alt="" /></div>    
                    <div className="slide-img"><img src={ex2} alt="" /></div>
                    <div className="slide-img"><img src={ex3} alt=""/></div>   
                </Slider> 
                </div> 
                <div className="vertSlide">
                <Slider {...settings}> 
                    <div className="slide-img"><img src={ex1tall} alt="" /></div>    
                    <div className="slide-img"><img src={ex2tall} alt="" /></div>
                    <div className="slide-img"><img src={ex3tall} alt=""/></div>   
                </Slider> 
                </div> 
                </Container>  
            </div>
        )

    }

  }
