import React, { Component } from "react";
import { Row, Container } from "../Grid";
import './style.css';

class Steps extends Component {
    constructor(props) {
        super(props)
        this.state = {
            stepName: "translation"       
        }
    }

    translClic = e => {
        e.preventDefault();
        this.setState({stepName: "translation"})
    }
    audioClic = e => {
        e.preventDefault();
        this.setState({stepName: "audio"})
    }
    mediaClic = e => {
        e.preventDefault();
        this.setState({stepName: "graph"})
    }

    render() {
        let graphic;
        if(this.state.stepName === "translation") {
            graphic =  <div id="processmenus">
                <ul className="color3"> 
                    {/* <li className="active"><a className="wheellink" href="/our-process" onClick={this.translClic}>Translation</a></li>  */}
                    <li className="active"><a className="wheellink" href="/our-process" onClick={this.translClic}>Recruiting</a></li> 
                    <li><a className="wheellink" href="/our-process" onClick={this.audioClic}>Translation with audio</a></li>
                    <li><a className="wheellink" href="/our-process" onClick={this.mediaClic}>Translation with graphic</a></li> 
                </ul>
                <div className="wheelcontainer" id="wheel3">
                <div className="wheeler">
                <ol className="wheel translation">
                    {/* <li className="clearfix"><span>1.</span><div>The project manager receives your job.<br/>
                    The PM is your point of contact and responsible for project team selection.</div></li>  
                    <li className="clearfix"><span>2.</span><div>The translation team translates the project.</div></li> <li className="clearfix izq"><span>3.</span><div>The editing team extensively reviews the material ensuring that terminology, tone &amp; style are correct.</div></li>
                    <li className="clearfix izq"><span>4.</span><div>The proofreader scrutinizes the text to make sure there are no typos, misalignments, or grammar and syntax errors.</div></li> 
                    <li className="clearfix izq"><span>5.</span><div>The PM delivers the completed <br/>
                    project to the client. </div></li>  */}
                    <li className="clearfix"><span>1.</span><div>A vendor manager analyzes the clients <br/>needs
                    to find the right professionals for the project.</div></li>  
                    <li className="clearfix"><span>2.</span><div>From our large database, the recruiting team extensively reviews candidates' certifications, qualifications and experience.</div></li> <li className="clearfix izq"><span>3.</span><div>We contact selected candidates to arrange details and we also take tests whenever is necessary.</div></li>
                    <li className="clearfix izq"><span>4.</span><div>A final round of a detailed screening is performed before starting the project.</div></li> 
                    <li className="clearfix izq"><span>5.</span><div>The Project Manager gives the final<br/>
                    greenlight to begin.</div></li>    
                </ol>
                </div>
                </div>
                </div>
        } else if(this.state.stepName === "audio") {
            graphic =  <div id="processmenus">
                <ul className="color1"> 
                    <li><a className="wheellink" href="/our-process" onClick={this.translClic}>Translation</a></li> 
                    <li className="active"><a className="wheellink" href="/our-process" onClick={this.audioClic}>Translation with audio</a></li>
                    <li><a className="wheellink" href="/our-process" onClick={this.mediaClic}>Translation with graphic</a></li> 
                </ul>
                <div className="wheelcontainer" id="wheel3">
                <div className="wheeler">
                <ol className="wheel audio">
                    <li className="clearfix"><span>1.</span><div>The project manager receives your job. <br/>
                    The PM is your point of contact and responsible for project team selection. <br/>
                    </div></li>
                    <li className="clearfix"><span>2.</span><div>If your audio is not already in transcript form, our team will transcribe the audio for you.<br/>
                    <br/>
                    </div></li>
                    <li className="clearfix"><span>3.</span><div>The translation team <br/>
                    translates the audio script.<br/>
                    </div></li> 
                    <li className="clearfix"><span>4.</span><div>The editing team extensively reviews the material ensuring that terminology, tone &amp; style are correct.</div></li>
                    <li className="clearfix izq"><span>5.</span><div>The project manager casts the best voice talent and presents it to you for approval.</div></li>
                    <li className="clearfix izq"><span>6.</span><div>The audio/video is recorded using the approved talent.</div></li>
                    <li className="clearfix izq"><span>7.</span><div>Our A/V production team edits the recorded audio or video and incorporates any necessary soundtrack/sound effects.</div></li>
                    <li className="clearfix izq"><span>8.</span><div>Along with a linguist, our A/V production team runs a final quality control check on the finished content.</div></li>
                    <li className="clearfix izq"><span>9.</span><div>The PM delivers the completed project to you in the desired audio or video formats.</div></li>
                </ol>
                </div>
                </div>
                </div>
        } else if(this.state.stepName === "graph") {
            graphic =  <div id="processmenus">
                <ul className="color2"> 
                    <li><a className="wheellink" href="/our-process" onClick={this.translClic}>Translation</a></li> 
                    <li><a className="wheellink" href="/our-process" onClick={this.audioClic}>Translation with audio</a></li>
                    <li className="active"><a className="wheellink" href="/our-process" onClick={this.mediaClic}>Translation with graphic</a></li> 
                </ul>
                <div className="wheelcontainer" id="wheel2">
                <div className="wheeler">                
                <ol className="wheel graph">
                    <li className="clearfix"><span>1.</span><div>The project manager receives your job. <br/>
                    The PM is your point of contact and responsible for project team selection. <br/>
                    </div></li>
                    <li className="clearfix"><span>2.</span><div>The translation team translates the project, including any graphics-embedded text.
                    </div></li>
                    <li className="clearfix"><span>3.</span><div>The editing team extensively reviews the material ensuring that terminology, tone &amp; style are correct.<br/>
                    </div></li> 
                    <li className="clearfix izq"><span>4.</span><div>Our graphics team formats the document to fit into existing layouts in any format necessary (jpg, gif, png, pdf, doc, ppt, etc.).</div></li>
                    <li className="clearfix izq"><span>5.</span><div>One of our graphics editors performs a quality control check on the project.</div></li>
                    <li className="clearfix izq"><span>6.</span><div>The proofreader scrutinizes the text to make sure there are no typos, misalignments, or grammar and syntax errors.</div></li>
                    <li className="clearfix izq"><span>7.</span><div>The PM delivers the completed project to you in the same format and with the same graphic design as the original, or in any other format or layout desired.</div></li>   
                </ol>
                </div>
                </div>
                </div>
        }
    return (
        <div id="banner_process" className="steps">
            <Container>
            <h1>Every step necessary&nbsp;to ensure quality</h1>
            
            {graphic}
            
            <Row>
                
            </Row>
            </Container>  
        </div>
    );
}
}

export default Steps;

