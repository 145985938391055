import React, { Component } from "react";
import './nav.css';
import logo from '../../assets/mlg-logo.png';
import { Container } from '../Grid';

class Nav extends Component {
  render() {
    
    return (
      <nav className="navbar navbar-expand-lg  navbar-light">
        <Container id="navMargin">
          <a href="/" className="navbar-brand"><img src={logo} alt=""/></a>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMLG" aria-controls="navbarMLG" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse flex-column" id="navbarMLG">
            <ul className="navbar-nav ml-auto py-0">
              <li className="nav-item">
                  <a className="nav-link" href="/#jointhefamily">Opportunities</a>
              </li>
              <li className="nav-item">
                  <a className="nav-link" href="/#offic">Contact Us</a>
              </li>
            </ul>
            <ul className="navbar-nav ml-auto pb-0 main">
              <li className="nav-item active">
                <a className="nav-link" href="/our-services">Services</a>
              </li>
              <li className="nav-item active">
                {/* <a className="nav-link" href="/#people">Team</a> */}
                <a className="nav-link" href="/our-team">Team</a>

               
              </li>
              <li className="nav-item active">
                <a className="nav-link" href="/our-process">Our Process</a>
              </li>
            </ul>

          </div>

        </Container>

      </nav>
      
      
    );
  }
}

export default Nav;
