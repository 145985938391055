import React, { Component } from "react";
import { Container, Row } from "../Grid";
import './style.css';
import API from "../../utils/API";
import SweetAlert from 'react-bootstrap-sweetalert';
import FileUpload from '../FileUpload';
import moment from 'moment';

class FormFreel extends Component {
  constructor(props) {
    super(props)
    this.state = {   
      name: "",
      email: "",
      language: "",
      rate: "",
      timeZone: "",
      url: "",
      comments: "",
      dateAdded: moment().format("MM-DD-YYYY")
    }
  }
  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
        { [name]: value},
    );
  }
  handleDrop = e => {
    e.preventDefault();
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
        { [name]: value }
    );
  }
  handleSubmit = e => {
    e.preventDefault();
    let trimmedEmail = this.state.email.trim();     
    if(this.state.name === "" || this.state.email === "" || this.state.language === "") {

       const getAlert = () => (
        <SweetAlert 
        warning 
        title="Please fill out all required fields" 
        onConfirm={this.hideAlert} 
        >
        </SweetAlert>
      );
  
      this.setState({
          alert: getAlert()
      }); 
    } else {
      API.chCandEmail(trimmedEmail)
      .then(res => {
        if(res.data.length === 0) {
          API.saveCandidate({
            name: this.state.name,
            email: trimmedEmail,
            language: this.state.language,
            rate: this.state.rate,
            timeZone: this.state.timeZone,
            proz: this.state.url,
            notes: this.state.comments,
            origin: "Web",
            dateAdded: this.state.dateAdded
          })
          .then(res=> console.log(res.data));
          
          const getAlert = () => (
            <SweetAlert 
            success 
            title="Great!" 
            onConfirm={this.hideAlert} 
            >
              You sent your information succesfully
            </SweetAlert>
          );
      
          this.setState({
              alert: getAlert()
          });
        } else {
          const getAlert = () => (
            <SweetAlert
                warning  
                title="Your info is already in our database. We'll contact you as soon as we have a business opportunity. Thank you!"
                onConfirm={() => this.hideAlert()}
            />
        );    
        this.setState({
            alert: getAlert()
        });
        }
      })
         
    }   
  }

    hideAlert = () => {
      this.setState({
          alert: null,
          name: "",
          email: "",
          language: "",
          timeZone: "",
          url: "",
          comments: ""
      });
    }

  render() {
   
    return (
      <div className="freelance">
        <Container>
          <h1><span>Hi,</span> freelancers</h1>
          <div className="subtitle"><p>If you are interested in joining our net of professionals, please fill out the form below.</p></div>
          <Row>
            <div className="col-md-3"></div>
            <div className="col-md-6">
            <form>
              <div className="form-group">
                <label>Full Name *
                <input type="text" className="form-control" name="name" value={this.state.name} onChange={this.handleChange} autoComplete="off"/></label>
              </div>  
              <div className="form-group">
                <label>E-mail *
                <input type="text" className="form-control" name="email" value={this.state.email} onChange={this.handleChange} autoComplete="off"/></label>
              </div> 
              <div className="form-group">
                <label>Target Language *
                <select className="form-control" name="language" onChange={this.handleDrop} value={this.state.language}>
                  <option></option>
                  <option value="AM">Amharic</option>
                  <option value="AR">Arabic</option>
                  <option value="EU">Basque</option>
                  <option value="BN">Bengali</option>
                  <option value="BG">Bulgarian</option>
                  <option value="CA">Catalan</option>
                  <option value="ZS">Chinese (Simplified)</option>
                  <option value="ZT">Chinese (Traditional)</option>
                  <option value="CS">Czech</option>
                  <option value="DA">Danish</option>
                  <option value="DU">Dutch</option>
                  <option value="EN">English</option>
                  <option value="FI">Finnish</option>
                  <option value="FR Ca">French (Canada)</option>
                  <option value="FR Eu">French (Europe)</option>
                  <option value="GL">Galician</option>
                  <option value="DE">German</option>
                  <option value="EL">Greek</option> 
                  <option value="HT">Haitian Creole</option>
                  <option value="HE">Hebrew</option>
                  <option value="HI">Hindi</option>
                  <option value="HU">Hungarian</option>
                  <option value="IS">Icelandic</option>
                  <option value="ID">Indonesian</option>
                  <option value="IT">Italian</option>
                  <option value="JP">Japanese</option>
                  <option value="KA">Karen</option>
                  <option value="KO">Korean</option>
                  <option value="MS">Malay</option>
                  <option value="NE">Nepali</option>
                  <option value="NO">Norwegian</option>
                  <option value="PL">Polish</option>
                  <option value="PT Br">Portuguese (Brasil)</option>
                  <option value="PT Eu">Portuguese (Europe)</option>
                  <option value="PA">Punjabi</option>
                  <option value="RO">Romanian</option>
                  <option value="RU">Russian</option>
                  <option value="SK">Slovak</option>
                  <option value="SP">Spanish</option>
                  <option value="SP Eu">Spanish (Europe)</option>
                  <option value="SW">Swahili</option>
                  <option value="SV">Swedish</option>
                  <option value="TL">Tagalog</option>
                  <option value="TH">Thai</option>
                  <option value="TR">Turkish</option>
                  <option value="UR">Urdu</option>
                  <option value="VI">Vietnamese</option>
                  <option value="OT">Other</option>
                </select> </label>                       
              </div>
              <div className="form-group">
                <label>Rate per Word (US$)
                <input type="text" className="form-control" name="rate" value={this.state.rate} onChange={this.handleChange}/></label>
              </div> 
              <div className="form-group">
                  <label>Time Zone
                  <select type="text" className="form-control" name="timeZone" value={this.state.timeZone} onChange={this.handleDrop}> 
                  <option></option>
                  <option value="ET-6">UTC -11: American Samoa Time</option>
                  <option value="ET-5">UTC -10: Hawaii Time</option>
                  <option value="ET-4">UTC -9: Alaska Time</option>
                  <option value="ET-3">UTC -8: US Pacific Time</option>  
                  <option value="ET-2">UTC -7: US Mountain Standard Time</option> 
                  <option value="ET-1">UTC -6: US Central Standard Time</option>  
                  <option value="ET">UTC -5: US Eastern Standard Time</option>  
                  <option value="ET+1" >UTC -3: Argentina-Brazil Time</option>
                  <option value="ET+2" >UTC -2: Greenland Time</option>
                  <option value="ET+3" >UTC -1: Azores Time</option>
                  <option value="ET+4" >UTC: West Africa Time</option>
                  <option value="ET+5">UTC +1: Great Britain Time</option>
                  <option value="ET+6">UTC +2: Central European Time</option>   
                  <option value="ET+7">UTC +3: Moscow-Turkey Time</option>   
                  <option value="ET+8" >UTC +4: UAE Time</option>
                  <option value="ET+9" >UTC +5: Pakistan Time</option>
                  <option value="ET+10" >UTC +6: Bangladesh Time</option>
                  <option value="ET+11" >UTC +7: Vietnam-Thailand Time</option>
                  <option value="ET+12">UTC +8: China Time</option>   
                  <option value="ET+13">UTC +9: Japan-Korea Time</option>
                  <option value="ET+14" >UTC +10: Australia Time</option>   
                  </select></label>
              </div>   
              <div className="form-group">
                  <label>PROZ/Linkedin URL
                  <input type="text" className="form-control" name="url" value={this.state.url} onChange={this.handleChange}/></label>
              </div>       
              {/* <div className="filesAtt">
                <a href="/" id="pickfiles">Attach Resume (.doc, .docx, .rtf, .pdf, .txt, .html files only)</a>
              </div> */}
              <FileUpload id="pickfiles" />
              <div className="form-group">
                  <label>Comments
                  <textarea className="form-control" name="comments" value={this.state.comments} onChange={this.handleChange} rows="4" /></label>
              </div>
              <div className="">
                  <p>* Required fields</p>
              </div>
              <div className="form-button">
                <button id="btfreel" onClick={this.handleSubmit}>SEND</button>
              </div>
            </form>
            </div>
            <div className="col-md-3"></div>

          </Row>     
        </Container>
        {this.state.alert}
      </div>

    );
  }
}

export default FormFreel;
