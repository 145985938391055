import React, {Component} from "react";
import { FormBtn } from "../Form";
import { Row, Container } from "../Grid";
import './style.css';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import FileUpload from '../FileUpload';

class Contact extends Component {
  constructor(props) {
    super(props)
    this.state = {
        name: "",
        email: "",
        phone: "",
        message: ""
    }    
  }
  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
        { [name]: value }
    );
  }
  handleSubmit = e => {
    e.preventDefault();

    axios({
      method: "POST", 
      url:"/api/email", 
      data: {
          name: this.state.name,   
          email: this.state.email,  
          text: this.state.message
      }
    })
    .then((response)=>{
    console.log(response)
      if (response.data.msg === 'success'){
          const getAlert = () => (
            <SweetAlert 
            success 
            title="Thank you!" 
            onConfirm={this.hideAlert} 
            >
              We'll get back at you as soon as possible.
            </SweetAlert>
          );
          this.setState({
            alert: getAlert()
          }); 
      }else if(response.data.msg === 'fail'){
        const getAlert = () => (
          <SweetAlert 
          warning 
          title="Your message couldn't be sent." 
          onConfirm={this.hideAlert} 
          >
            Please, send us the message again. Thanks.  
          </SweetAlert>
        );
        this.setState({
          alert: getAlert()
        }); 
      }
    }) 
}
hideAlert = () => {
  this.setState({
      alert: null,
      name: "",
      email: "",
      message: ""
  });
}
onChange = e => {
  console.log(e.target)
}

  render() {
    
    return (
      <div className="block contactus" id="contactus">
        <Container> 
          <h2>Let us know your project needs. Drop us a line, we'll contact you today!</h2>
            <Row>
              <div className="col-md-7 col-sm-12 left form1">
                <Row>
                  <div className="col-md-6">
                    <input type="text" name="name" placeholder="Name" value={this.state.name ? this.state.name : ""} onChange={this.handleChange}/>
                    <input type="text" name="email" placeholder="Email" value={this.state.email ? this.state.email : ""} onChange={this.handleChange}/>
                    <input type="text" name="phone" placeholder="Phone" value={this.state.phone ? this.state.phone : ""} onChange={this.handleChange}/>
                  </div>
                  <div className="col-md-5 left2">
                    <input type="text" name="message" placeholder="Write us something nice" value={this.state.message ? this.state.message : ""} onChange={this.handleChange}></input>
                    {/* <a href="/" id="pickfiles">Need to attach a file?</a> */}
                    <FileUpload id="pickfiles"/>
                    <FormBtn id="btyellow" onClick={this.handleSubmit}>Drop us a line</FormBtn>
                  </div>
                </Row>
              </div>
              <div className="col-md-4 col-sm-12 right">
                  {/* <p>You can also contact us at:</p> */}
                  {/* <br /><br />
                  <h4 className="telephone">2980 McFarlane Rd<br></br>Miami, FL 33133</h4>
                  <h4 className="telephone">+1-786-306-1924</h4> */}
                  <a style={{fontSize: '1.2em'}} href="mailto:info@mlginternational.net">info@mlginternational.net</a>
              </div>
            </Row>
        </Container> 
        {this.state.alert}     
      </div>
    );
  }

    
  
}

export default Contact;