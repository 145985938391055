import React from "react";
import Nav from "../components/NewNav";
import PrivacyPolicy from "../components/PrivacyPolicy";
// import Contact from "../components/Contact";
import { Footer } from "../components/Footer";
import Offices from "../components/Offices";

export function Privacy() {
    
    return(
        <div>
        <Nav />
        <PrivacyPolicy />
        <Offices />
        {/* <Contact /> */}
        <Footer />
        </div>
    )
}