import React from "react";
import { Row, Container } from "../Grid";
import './style.css';
import trans from '../../assets/ico-translation-white.png';
import cycle from '../../assets/ico-cycle.png';

export function Translation () {

    return (
        <div className="bgcleargreen translation" id="translation">
            <Container>
            <Row>
                <div className="col-md-3 first">
                    <img src={trans} width="97" height="73" alt=""/>    
                    <h3>LINGUISTIC TRAINING</h3>     
                    <p>Train your AI systems with our certified, degreed and experienced linguists</p>        
                </div>
                <div className="col-md-6 second">        
                    <p>Our Linguistic AI Training service is designed to help businesses and organizations train their artificial intelligence systems to better understand and communicate with humans. We provide expert training in natural language processing, and other multilingual AI-related fields, so your systems can learn to understand the nuances of human language and respond in a way that feels natural and intuitive.</p>
                </div> 
                <div className="col-md-3 third">
                    <img src={cycle} width="123" height="123" alt=""/>
                    <h3>We power companies with human linguistic AI training</h3>
                </div>
            </Row>
            </Container>  
        </div>
    );
}

