import React, { Component } from "react";
import { Container } from "../components/Grid";
// import Nav from "../components/Nav";
import Nav from "../components/NewNav";
import HomeBanner from "../components/HomeBanner";
import { HelpYou } from "../components/HelpYou";
// import AboutUs from "../components/AboutUS";
// import Contact from "../components/Contact";
import { Footer } from "../components/Footer";
import Clients from "../components/Clients";
import Offices from "../components/Offices";
import Testimonials from "../components/Testimonials";
import People from "../components/People";
import JoinTeam from "../components/JoinTeam";

class Main extends Component {
    constructor(props) {
        super(props)
        this.state = {
            referrer: null
        }
    }

    render() {
        return(
            <div>
            <Nav />
            <Container>
            <HomeBanner />
            </Container>
            <HelpYou />
            <Clients />
            <People />
            <Testimonials />
            <JoinTeam /> 
            <Offices />
                       
            {/* <AboutUs /> */}
            {/* <Contact /> */}
            <Footer />
            </div>
        )
    }
}

export default Main;