import React, { Component } from "react";
import { Container } from "../components/Grid";
import logo from '../assets/mlg-logo.png';
import { Redirect } from "react-router-dom";


class NoMatch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            referrer: null
        }
    }

    logOutPage = () => {
        this.setState({ referrer: '/' });
    }
    
    render() {
        const { referrer } = this.state;
            if (referrer) return <Redirect to={{ pathname: referrer}} />;
        const stylesObj = {
            background: '#eeecec',
            position: 'fixed',
            width: '100%',
            height: '100%'
        };
        return (
            <div style = {stylesObj}> 
                <Container >
                    <div style={{marginTop: '100px', textAlign: 'center'}}>
                        <img src={logo} width="auto" height="auto" alt=""  onClick={this.logOutPage} style={{cursor: 'pointer'}}/>
                    </div>
                    <h1 style={{marginTop: '50px', textAlign: 'center'}}>404 Page Not Found</h1>
                    <h1 style={{marginTop: '20px', textAlign: 'center'}}>
                        <span role="img" aria-label="Face With Rolling Eyes Emoji">
                        🙄
                        </span>
                    </h1>
                </Container>
            </div>
        )
    }
}

export default NoMatch;
