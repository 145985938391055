import React from "react";
import { Container } from "../Grid";
import './style.css';
import how from '../../assets/ico-how2.png';

export function LearnMore () {

    return (
      <div className="bgclearorange">
        <Container>      
          <div className="learnmore">
            <img src={how} alt=""/>
            <h2><a title="Our Process" href="/our-process/">Learn More About How We Work<i className="fas fa-caret-right"></i></a></h2>
          </div>            
        </Container>
      </div>
    );
}

