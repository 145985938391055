import React, {Component} from "react";
import './style.css';
import { Container } from "../Grid";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import agr from '../../assets/agr.jpg';
import aol from '../../assets/aol.jpg';
import bbe from '../../assets/bbe.jpg';
import dep from '../../assets/dep.jpg';
import dre from '../../assets/dre.png';
import esk from '../../assets/esk.jpg';
import fid from '../../assets/fid.jpg';
import forest from '../../assets/for.jpg';
import gir from '../../assets/gir.jpg';
import kb4 from '../../assets/kb4.png';
import lat from '../../assets/lat.jpg';
import maa from '../../assets/maa.png';
import mei from '../../assets/mei.jpg';
import mp3 from '../../assets/mp3.png';
import msc from '../../assets/msc.png';
import mul from '../../assets/mul.jpg';
import oly from '../../assets/oly.jpg';
import par from '../../assets/par.png';
import per from '../../assets/per.jpg';
import sem from '../../assets/sem.jpg';
import son from '../../assets/son.jpg';
import tot from '../../assets/tot.png';
import tte from '../../assets/tte.jpg';
import uni from '../../assets/uni.jpg';
import wau from '../../assets/wau.jpg';
import whi from '../../assets/whi.png';
import scl from '../../assets/scl.png';
import cr7 from '../../assets/cr7.png';
import cyl from '../../assets/cyl.png';

class Clients extends Component {
 
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 1500,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: true,
      autoplay: false,
      // autoplaySpeed: 2800,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: true
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            arrows: true
          }
        }
      ]
    };

    return (
      <div className="clients">
        <Container>        
          <div className="helpyou">
            <h2>Some of our clients</h2>
          </div>
          <Slider {...settings}> 
            {/* <div className="slide-img"><img src={scl} alt=""/><img src={par} style={{marginTop: '20px'}} alt=""/></div>   */}
            {/* <div className="slide-img"><img src={mp3} alt=""/><img src={par} style={{marginTop: '20px'}} alt=""/></div> */}
            <div className="slide-img"><img src={mp3} style={{marginTop: '20px'}} alt=""/><img src={scl} style={{marginTop: '20px'}} alt=""/></div>
            <div className="slide-img"><img src={kb4} style={{marginTop: '20px'}} alt=""/><img src={par} style={{marginTop: '40px'}} alt=""/></div>
            <div className="slide-img"><img src={oly} style={{marginTop: '10px'}} alt=""/><img src={msc} style={{marginTop: '60px'}} alt=""/></div>
            <div className="slide-img"><img src={tte} style={{marginTop: '20px'}} alt=""/><img src={son} style={{marginTop: '50px'}} alt=""/></div>

            {/* <div className="slide-img"><img src={tte} alt=""/><img src={mei} style={{marginTop: '20px'}} alt=""/></div>  
            <div className="slide-img"><img src={tot} alt=""/><img src={dep} style={{marginTop: '40px'}} alt=""/></div>
            <div className="slide-img"><img src={per} alt=""/><img src={dre} style={{marginTop: '60px'}} alt=""/></div>
            <div className="slide-img"><img src={maa} alt=""/><img src={esk} style={{marginTop: '50px'}} alt=""/></div> */}
            <div className="slide-img"><img src={tot} alt=""/><img src={mei} style={{marginTop: '20px'}} alt=""/></div>  
            <div className="slide-img"><img src={per} alt=""/><img src={dep} style={{marginTop: '40px'}} alt=""/></div>
            <div className="slide-img"><img src={maa} alt=""/><img src={dre} style={{marginTop: '60px'}} alt=""/></div>
            <div className="slide-img"><img src={fid} alt=""/><img src={bbe} style={{marginTop: '50px'}} alt=""/></div>
            <div className="slide-img"><img src={forest} alt=""/><img src={lat} style={{marginTop: '20px'}} alt=""/></div>  
            <div className="slide-img"><img src={agr} alt=""/><img src={mul} style={{marginTop: '40px'}} alt=""/></div>
            <div className="slide-img"><img src={gir} alt=""/><img src={aol} style={{marginTop: '60px'}} alt=""/></div>
            <div className="slide-img"><img src={uni} alt=""/><img src={sem} style={{marginTop: '50px'}} alt=""/></div>
            <div className="slide-img"><img src={wau} alt="" /><img src={cr7} style={{marginTop: '20px'}} alt=""/></div>
            <div className="slide-img"><img src={whi} alt=""/><img src={cyl} style={{marginTop: '60px'}} alt=""/></div>
            <div className="slide-img"><img src={esk} alt=""/></div>
          </Slider> 
        </Container>
      </div>
    );
  }

}

export default Clients;
