import React from "react";
// import { Link } from "react-router-dom";
import './style.css';
import { Container, Row } from '../Grid';

export function Footer () {
  var today = new Date();
  var year = today.getFullYear();

  return(
    <footer id="footer">
      <Container>   
        <Row>          
        <div className="col-md-5 copy">Copyright&copy; { year } MLG International LLC. All Rights Reserved</div>
        <div className="col-md-5 links">
            <a href="/privacy-policy">Privacy Policy</a>
            <a href="/terms-and-conditions">Terms and conditions</a>
        </div>
        </Row>       
      </Container>
    </footer>
  )
}

export default Footer;
