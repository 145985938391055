import React, { Component } from "react";
import Nav from "../components/NewNav";
import FormFreel from "../components/FormFreel";
import { FooterFixed } from "../components/FooterFixed";


class Freelancers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            referrer: null
        }
    }
    render() {
        return(
            <div>
            <Nav />
            <FormFreel />
            <FooterFixed />
            </div>
        )
    }
}
export default Freelancers;