import React from "react";
import { Row, Container } from "../Grid";
import './style.css';
import graph from '../../assets/ico-graphics.png';
import board from '../../assets/ico-graphics-nw.png';

export function Graphics () {

    return (
        <div className="graphics" id="graphics">
            <Container>
            <Row>
            <div className="col-md-3 icon">
                <img src={graph} width="68" height="84" alt=""/>
                <h3>LOCALIZATION</h3>
                <p>Adapt your content to foreign markets</p>
            </div>
            <div className="col-md-6 text">
                <p>We adapt concepts, ideas and visions to ensure their relevance to the nuances of each individual culture.</p>
                <p>We are committed to providing native-smooth and accurate translations that also take into consideration the cultural context of terminology, imagery and tone – we speak your language and culture.</p>
                <p>We work in over 65 languages.</p>
            </div>
            <div className="col-md-3 more">
                <img src={board} width="109" height="126" alt=""/>
                <h3>We'll get the job done, with or without your source files</h3>
            </div>
            </Row>
            </Container>  
        </div>
    );
}

