import React from "react";
import { Row, Container } from "../Grid";
import './style.css';
import mark from '../../assets/ico-marketing.png';
import bars from '../../assets/ico-bars.png';

export function Marketing () {

    return (
        <div className="marketing" id="marketing">
            <Container>
            <Row>
            <div className="col-md-3 icon">
                <img src={mark} width="104" height="84" alt=""/>
                <h3>MARKETING</h3>
                <p>Deploy a creative team in any language</p>
            </div>
            <div className="col-md-6 texts">
                <p>We understand that in some cases, translating your content may not be enough – therefore we adapt your message to target to each specific market desired. Our team of copywriters and localization specialists will craft the language to ensure that your product or service is appealing and culturally relevant to your target market.</p>
                <p>Let us transcreate your message to optimize its impact within a broad range of cultural climates.</p>
                <p>&nbsp;</p>
            </div>
            <div className="col-md-3 more">
                <img src={bars} width="127" height="127" alt=""/>
                <h3>We'll help you reach a larger audience</h3>
            </div>
            </Row>
            </Container>  
        </div>
    );
}

