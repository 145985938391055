import React, {Component, useState, useEffect } from "react";
import './style.css';
import { Col, Row } from '../Grid';
// import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import people from '../../assets/150team.png';
import map from '../../assets/worldmap.png';
import arrows from '../../assets/arrows.png';
import write from '../../assets/writing.png';
import video from '../../assets/video.png';
import fort from '../../assets/fortunecompanies.png';

function WordSwapper() {
  const words = ['Hello', 'Hola', 'Bonjour', 'Guten Tag','你好', 'Olá', 'こんにちは', 'Ciao', 'привет', 'مرحبًا'];
  const [currentWord, setCurrentWord] = useState(words[0]);
  
  useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = words.indexOf(currentWord);
      const nextIndex = (currentIndex + 1) % words.length;
      setCurrentWord(words[nextIndex]);
    }, 2000);
    return () => clearInterval(interval);
  }, [currentWord, words]);

  return <h1>{currentWord}</h1>;
}

class HomeBanner extends Component {
// export function HomeBanner () {
  
  render() {
    // const settings = {
    //   dots: false,
    //   infinite: true,
    //   speed: 2300,
    //   autoplay: true,
    //   autoplaySpeed: 1200,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   arrows: false,
    //   responsive: [
    //     {
    //       breakpoint: 1024,
    //       settings: {
    //         infinite: true
    //       }
    //     }
    //   ]
    // };

    return (
      <div id="homeBanner">
          <Row>
            <Col size="md-6">
              <div id="banner1">
                <br />
                <br />
                <br />
                <br />
                {/* <div><Slider {...settings}>
                  <div><h2>Hello</h2></div>
                  <div><h2>Hola</h2></div>
                  <div><h2>Bonjour</h2></div>
                  <div><h2>Guten Tag</h2></div>
                  <div><h2>Olá</h2></div>
                  <div><h2>こんにちは</h2></div>
                  <div><h2>你好</h2></div>
                  <div><h2>Ciao</h2></div>
                  <div><h2>привет</h2></div>
                  <div><h2>مرحبًا</h2></div>
                </Slider>  </div> */}
                <div><WordSwapper/></div>
                {/* <h3>We speak the world's language</h3> */}
                <h4>We do much more than translations. <br></br> Our linguists train AI language models, our multimedia team faces projects in any format, and our QA Team ensures a smooth UX/UI experience in any multilingual website. </h4>
                {/* <h4>We offer language QA, linguistic research, Desktop Publishing, and Transcreation.</h4> */}
                {/* <button><a href="/our-company">Learn more</a></button> */}
              </div>
              <div id="banner2">
                <Row>
                  <Col size="md-6 sm-6">
                    <p id="mlg">At MLG we have</p>  
                    <Row>
                      <Col size="md-7">
                        <div id="number">10,000+</div>
                      </Col>
                      <Col size="md-5">
                        <div id="text">
                            certified, degreed &<br />
                            experienced<br />
                            linguists
                        </div>                        
                      </Col>     
                      <img src={people} id="people" alt=""/>             
                    </Row>
                  </Col>
                  <Col size="md-6 sm-6">
                  <p>with a strong presence</p>
                    {/* <p>with a<br /><span className="fs25">STRONG PRESENCE</span></p> */}
                      <p className="map"><img src={map} id="map" alt=""/></p>
                      <p className="fs17">in the USA, Argentina, Spain, Colombia & other 50 countries</p>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col size="md-3">
              <div id="banner3">                
                <p className="topText">We have translated over<br/><span className="fs40">500,000,000</span><br/> <span className="fs20">words</span></p>
                <img src={arrows} alt=""/>
                <p className="fs20">(and counting)</p>
              </div>
              <div id="banner4">
                <p className="fs20">formatted more than<br/><span className="fs45">2,500,000</span></p> 
                <img src={write} alt=""/>
                <p className="fs20">graphics in<br/><span className="fs30">45 </span> languages</p>
              </div>
            </Col>

            <Col size="md-3">
              <div id="banner5">                
                <p className="fs20" style={{paddingTop:"10px"}}>We have completed over<br/><span className="fs40">3,000</span></p>
                <img src={video} alt=""/>
                <p className="fs20">subtitling, voiceover <br />and QA projects</p>
              </div>
              <div id="banner6">
                <p className="fs20t">And in the last<br/><span className="fs40">19 years,</span><br/>we served<br/><span className="fs20">450+</span> clients</p>
                <Row>
                  <Col size="md-4">
                    <div className="fs17">including<br/>many</div>
                  </Col>
                  <Col size="md-4">
                    <img style={{ paddingTop: '10px'}} src={fort} alt=""/>
                  </Col>
                </Row>
                <p className="fs16">Fortune 500 Companies</p>
              </div>
            </Col>
          </Row>
        </div>
    );
  }
}
export default HomeBanner;