import React from "react";
import Nav from "../components/Nav";
import CompSlid from "../components/CompSlid";
import Offices from "../components/Offices";
import Contact from "../components/Contact";
import Clients from "../components/Clients";
import Testimonials from "../components/Testimonials";
import Examples from "../components/Examples";
import { LearnMore } from "../components/LearnMore";
import { Footer } from "../components/Footer";


export function Company() {   
   
    return(
        <div>
        <Nav />
        <CompSlid />
        <Offices />
        <Clients />
        <Testimonials />
        <Examples />
        <LearnMore />
        <Contact />
        <Footer />
        </div>
    )    
}

