import React from "react";
import { Row, Container } from "../Grid";
import './style.css';
import audio from '../../assets/ico-sound.png';
import graphics from '../../assets/ico-graphics.png';
import trans from '../../assets/ico-translation.png';
import market from '../../assets/ico-marketing.png';

export function HelpYou () {

    return (
      <div className="block">  
        <Container>

        <div className="helpYou">
        <h2>One agency for all your language service needs.</h2>        
        <div className="message">
          {/* <p>We connect you with clients in over 45 languages.</p> */}
          {/* <p>We are a one stop solution for e-learning global companies.</p> */}
          {/* <br></br> */}
        </div>
        <br></br>
        </div>

        <div className="features">
          <Row >
          <div className="col-md-3  text-center">
          <a href="/our-services#translation">
            <h3>LINGUISTIC TRAINING</h3>
            <img src={trans} width="97" height="84" alt=""  />
            <h4>Train your AI systems with our linguistic experts</h4>
            </a>
            {/* <p>We deliver the highest level of service with a personalized approach. We ensure quality with a three-step translation, editing, and proofreading process.</p> */}
          </div>
            <div className="col-md-3  text-center">
            <a href="/our-services#audio">
              <h3>MULTIMEDIA</h3>
              <img src={audio} width="96" height="84" alt=""/>
              <h4>Present your multimedia content in any language</h4>
            </a>
              {/* <p>We deliver complex short video projects in just a few days and at cost-efficient rates. Our services include: subtitling, voiceover, dubbing, and text editing.</p> */}
            </div>
          <div className="col-md-3  text-center graph">
          <a href="/our-services#graphics">
            <h3>LOCALIZATION</h3>
            <img src={graphics} width="68" height="84" alt=""/>
            <h4>Speak your client's language - and culture</h4>
            </a>
            {/* <p>Our in-house team of graphic design experts is ready to help you with projects in any format, whether you have the source files or not.</p> */}
          </div>
          
          <div className="col-md-3  text-center">
          <a href="/our-services#marketing">
            <h3>MARKETING</h3>
            <img src={market} width="104" height="84" alt=""/>
            <h4>Deploy a creative team in any language</h4>
            </a>
            {/* <p>Sometimes translation alone is not enough. Our team of copywriters re-creates your message to appeal to each specific market you want to target.</p> */}
          </div>
        </Row>
        {/* <div className="text-center foot1">
            <a href="/our-services" className="learnmore">Learn more about what we do</a>
        </div> */}
        </div>
        <div className="message"></div>
        </Container> 
      </div>
    );
  
}

