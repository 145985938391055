import axios from "axios";

export default {
    saveCandidate: function(data) {
        return axios.post("/api/candidate", data);
    },
    chCandEmail: function(email) {
        return axios.get("/api/candidate/filter/" + email)
    },
}
