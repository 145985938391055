import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from './pages/Main';
import { Company } from './pages/Company';
import Team from './pages/Team';
import { Services } from './pages/Services';
import { Process } from './pages/Process';
import { Work } from './pages/Work';
import NoMatch from "./pages/NoMatch";
import Freelancers from "./pages/Freelancers";
import Jobs from "./pages/Jobs";
import Partner from "./pages/Partner";
import { Privacy } from "./pages/Privacy";
import { Terms } from "./pages/Terms";

function App() {
  return (
    <Router>
    <div className="App">
    <Switch> 
      <Route exact path="/" component={Main} />
      <Route exact path="/our-company" component={Company} />
      <Route exact path="/our-team" component={Team} />
      <Route exact path="/our-services" component={Services} />
      <Route exact path="/our-process" component={Process} />
      <Route exact path="/our-work" component={Work} />
      <Route exact path="/our-team/#jointhefamily" component={Team} />
      <Route exact path="/#contactus" component={Main} />
      <Route exact path="/freelancers" component={Freelancers} />
      <Route exact path="/our-team/full-time-jobs" component={Jobs} />
      <Route exact path="/partner" component={Partner} />
      <Route exact path="/privacy-policy" component={Privacy} />
      <Route exact path="/terms-and-conditions" component={Terms} />
      <Route component={NoMatch} />
      
    </Switch>
    </div>
    </Router>
  );
}

export default App;
