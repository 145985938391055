import React from "react";
import { Row, Container } from "../Grid";
import './style.css';
import sound from '../../assets/ico-sound-white.png';

export function Audio () {

    return (
        <div className="audio" id="audio">
            <Container>
            <Row>
                <div className="col-md-3 icon">
                    <img src={sound} width="96" height="84" alt=""/>
                    <h3>MULTIMEDIA</h3>
                    <p>Present your A/V content in any language</p>
                </div>
                <div className="col-md-6 texts">
                    <p>Our graphic design team formats text and images to fit into existing layouts while retaining your site’s cohesive look and feel, solidly maintaining your established brand identity.</p>
                    <p>MLG International transcribes, translates, and produces professional-quality multilingual audio and video content.</p>
                    <p>Our language conversion of feature films, corporate videos, documentaries, presentations, reports, animations and more allows your company to increase market reach abroad – as well as in your home country.</p>
                </div>
                <div className="col-md-3 more ">
                    <ul className="">
                        <li>Subtitles</li>
                        <li>Transcriptions </li>
                        <li>Dubbing</li>
                        <li>Voice-Over / Lip-Sync</li>
                        <li>Sound &amp; Video Editing</li>
                    </ul>
                </div>
            </Row>
            </Container>  
        </div>
    );
}

