import React from "react";
import { Container, Row } from "../Grid";
import './style.css';
import trans from '../../assets/ico-translation-pq.png';
import grap from '../../assets/ico-graphics-pq.png';
import audio from '../../assets/ico-sound-pq.png';
import mark from '../../assets/ico-marketing-pq.png';

export function ServBanner () {

    return (
      <div className="bgcreamy">
        <Container>
          <h1>One agency for all your multilingual service needs</h1>  
          <div className="features">
          <Row>          
          <div className="col-md-3">
              <a href="#graphics"><img src={grap} alt=""/>
                <h3>LOCALIZATION</h3>
              </a>
            </div>
            <div className="col-md-3">
              <a href="#translation"><img src={trans} alt=""/>
                <h3>LINGUISTIC TRAINING</h3>
              </a>
            </div>
            
            <div className="col-md-3">
              <a href="#audio"><img src={audio} alt=""/>
                <h3>MULTIMEDIA</h3>
              </a>
            </div>
            <div className="col-md-3">
              <a href="#marketing"><img src={mark} alt=""/>
                <h3>MARKETING</h3>
              </a>
            </div>  
          </Row> 
          </div>    
        </Container>    
      </div>
    );
}

