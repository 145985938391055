import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Container, Row } from "../Grid";
import './style.css';
import home from '../../assets/home.png';
// import meet from '../../assets/meeting.png';
import part from '../../assets/partner.png';

class JoinTeam extends Component {
  constructor(props) {
    super(props)
    this.state = {         
      referrer: null
    }
  }

  seeContact = e => {
    e.preventDefault();
    this.setState({ referrer: '/freelancers' });
  }
  // seeJobs = e => {
  //   e.preventDefault();
  //   this.setState({ referrer: '/our-company' });
  // }
  seePartners = e => {
    e.preventDefault();
    this.setState({ referrer: '/partner' });
  }

  render() {
    const { referrer } = this.state;
    if (referrer) return <Redirect to={{ pathname: referrer}} />;

    return (
      <div className="team" id="jointhefamily">
        <Container>
          <h2>Join the Team</h2>
          <Row>
            {/* <div className="col-md-1 blueItem"></div> */}
            <div className="col-md-6 col-sm-12 blueItem">
              <img src={home} width="95" height="89" alt=""/>
              <h3>Freelancers</h3>
              <p>MLG is a growing agency and we’re currently expanding our network of freelance talent. If you’re interested in working with MLG, drop us a line and include your resume. We look forward to hearing from you!</p>
              <button onClick={this.seeContact}>Contact us</button>
            </div>
            {/* <div className="col-md-4 blueItem">
              <img src={meet} width="153" height="89" alt=""/>
              <h3>Full Time Jobs</h3>
              <p>MLG is currently seeking a full-time Business Development Specialist based in South Florida to help increase our client portfolio and generate new business.<br></br><br></br>
              Are you the one we’re looking for?</p>
              <button onClick={this.seeJobs}>Send your resume</button>
            </div> */}
            <div className="col-md-6  col-sm-12 blueItem">
              <img src={part} width="153" height="89" alt=""/>
              <h3>Partnership</h3>
              <p>If you are an agency and want to offer your clients translation, localization, or transcreation services, get in touch with us. We’ve already helped several agencies to diversify their service offerings – and increase their revenue.</p>
              <button onClick={this.seePartners}>Drop us a line</button>
            </div>
          </Row>
        </Container>
      </div>

    );
  }
}

export default JoinTeam;
