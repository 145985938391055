import React, { Component } from "react";
import { Row, Container } from "../Grid";
// import Tooltip from '@material-ui/core/Tooltip';
// import { withStyles } from '@material-ui/core/styles';
import './style.css';
import damian from '../../assets/damian.png';
import martin from '../../assets/martin.png';
import sil from '../../assets/sil.png';
import sol from '../../assets/sol.png';
import mercedes from '../../assets/mercedes.png';
import monica from '../../assets/monica.png';
import and from '../../assets/and.jpeg';
import juana from '../../assets/juana.png';
import sole from '../../assets/sole.png';
import lucho from '../../assets/lucho.png';
import andrea from '../../assets/andrea.jpg';
import ale from '../../assets/ale.jpg';
import seg from '../../assets/seg.jpg';
import tot from '../../assets/rog.jpg';

class People extends Component {

      render() {
        //   const HtmlTooltip = withStyles(theme => ({
        //     tooltip: {
        //       backgroundColor: '#0088cc',
        //       color: '#ffffff',
        //       maxWidth: 220,
        //       fontFamily: 'museo500',
        //       fontSize: 14,
        //       border: '1px solid #dadde9',
        //       textAlign: "center"
        //     },
        //   }))(Tooltip);
        return (
            <div className="people">
                <Container>
                <h1>This is us.<br/>
                Great people, better professionals.</h1>
                <Row>
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <p className="subtitle">MLG was founded nineteen years ago as a one-person startup. Shortly thereafter, a few members were added to form our team. Since then we’ve been fortunate to expand the team significantly. 
                        <br />
                        This is our leadership team:
                        </p>
                    </div>
                    <div className="col-md-2"></div>
                </Row>
                    <div className="row headshots">
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={martin} alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Martin Galarce</h6>
                                    <p className="card-text">CEO - Founder</p>
                                </div>
                            </div> 
                        </div>
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={sil}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Silvina Mazzia</h6>
                                    <p className="card-text">COO</p>
                                </div>
                            </div> 
                        </div>
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={sol}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Sol Marnotes</h6>
                                    <p className="card-text">Head of Project Management</p>
                                </div>
                            </div> 
                        </div>
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={monica}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Monica Paredes</h6>
                                    <p className="card-text">Head of Recruiting</p>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="row headshots">
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={mercedes}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Mercedes Galarce</h6>
                                    <p className="card-text">Senior Project Manager</p>
                                </div>
                            </div> 
                        </div>
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={damian}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Damian Defant</h6>
                                    <p className="card-text">Senior Multimedia Manager</p>
                                </div>
                            </div> 
                        </div>
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={sole}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Soledad Palacios</h6>
                                    <p className="card-text">Vendor Manager</p>
                                </div>
                            </div> 
                        </div>
                        
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={ale}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Alejandro Laita</h6>
                                    <p className="card-text">Video Specialist</p>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="row headshots">
                    <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={andrea}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Andrea Faggiani </h6>
                                    <p className="card-text">Multimedia Manager</p>
                                </div>
                            </div> 
                        </div>
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={and}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Andres Posso</h6>
                                    <p className="card-text">DTP Specialist</p>
                                </div>
                            </div> 
                        </div>
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={juana}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Juana Zuluaga</h6>
                                    <p className="card-text">QA Specialist</p>
                                </div>
                            </div> 
                        </div>
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={lucho}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Luciano del Zoppo</h6>
                                    <p className="card-text">Audio Specialist</p>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="row headshots">
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={seg}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Shirley Espert</h6>
                                    <p className="card-text">Head of IT</p>
                                </div>
                            </div> 
                        </div>
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={tot}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Rogelio Galarce</h6>
                                    <p className="card-text">HR & Office Manager</p>
                                </div>
                            </div> 
                        </div>
                        {/* <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={juana}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Juana Zuluaga</h6>
                                    <p className="card-text">QA Specialist</p>
                                </div>
                            </div> 
                        </div>
                        <div className="col-sm-3">
                            <div className="card text-center" style={{width: "12rem", backgroundColor: "transparent", borderColor: "transparent"}}>
                                <img className="img-thumbnail rounded-circle" src={lucho}  alt=""/>
                                <div className="card-body" style={{alignContent: "center"}}>
                                    <h6>Luciano del Zoppo</h6>
                                    <p className="card-text">Audio Specialist</p>
                                </div>
                            </div> 
                        </div> */}
                    </div>
                    <div >
                        <br />
                    </div>
                    {/* <HtmlTooltip
                        title={ 
                            <React.Fragment>
                            <b>{'Martin Galarce'}</b><br/>{'President - Founder'}
                            </React.Fragment>} 
                        placement="bottom">
                        <img src={martin}  alt=""/>            
                    </HtmlTooltip> */}
                    {/* <HtmlTooltip
                        title={ 
                            <React.Fragment>
                            <b>{'Silvina Mazzia'}</b><br/>{'Vice President'}
                            </React.Fragment>} 
                        placement="bottom">
                        <img src={sil}  alt=""/>            
                    </HtmlTooltip> 
                    <HtmlTooltip
                        title={ 
                            <React.Fragment>
                            <b>{'Sol Marnotes'}</b><br/>{'Sr Project Manager - Spanish Editor'}
                            </React.Fragment>} 
                        placement="bottom">
                        <img src={sol}  alt=""/>            
                    </HtmlTooltip>            
                    </HtmlTooltip>                                        
                    <HtmlTooltip
                        title={ 
                            <React.Fragment>
                            <b>{'Damian Defant'}</b><br/>{'Sr Manager - Graphics'}
                            </React.Fragment>} 
                        placement="top">
                        <img src={damian}  alt=""/>          
                    </HtmlTooltip>  */}
                    {/* </div> */}
                </Container>  
            </div>
        );
    }
}
export default People;