import React, { Component } from 'react';
import './style.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default class CompSlid extends Component {

  render() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              infinite: true,
              dots: false
            }
          }
        ]
      };
   
        return (
            <div className="slider">
                <Slider {...settings}>     
                    
                    <div>
                      <h2>We localize your business message to reach a larger audience.</h2>
                    </div>
                    <div>
                      <h2>We do more than just translate words – we adapt concepts, ideas and visions.</h2>
                    </div>
        
                </Slider>  
            </div>
        )

    }

}
