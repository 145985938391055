import React, { Component } from "react";
import { Container } from "../components/Grid";
import Nav from "../components/Nav";
import { Footer } from "../components/Footer";


class Jobs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            referrer: null
        }
    }


    render() {
        return(
            <div>
            <Nav />
                <Container>
                </Container>
            <Footer />
            </div>
        )
    }
}

export default Jobs;