import React from "react";
import Nav from "../components/Nav";
import Testimonials from "../components/Testimonials";
import Examples from "../components/Examples";
import Contact from "../components/Contact";
import { Footer } from "../components/Footer";


export function Work() {
    
        return(
            <div>
            <Nav />
            <Testimonials />
            <Examples />
            <Contact />
            <Footer />
            </div>
        )
    
}

