import React, {Component} from "react";
import { Row, Container, Col } from "../Grid";
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.min.css';
import './style.css';
import video from '../../assets/video_yt_cover.jpg';
import play from '../../assets/playvideo.png';
import ata from '../../assets/logo-ata.jpg';
import gsa from '../../assets/GSA_logo.jpg';
import top30low from '../../assets/top30low.png';

class Offices extends Component {
  constructor() {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }
  openModal = () => {
    this.setState({isOpen: true})
  }
  render() {
    return (
      <div className="offices" id="offic">
        <Container>
          <Row>
            <Col size="md-4">
              <div className="texts">
                <h2>About Us</h2>
                <p><strong> MLG International</strong> is a language service provider offering a wide range of integrated language solutions that include Translation, Editing, Recruiting, Quality Assurance, Desktop Publishing, Voiceover, Subtitling & Video Editing.</p>
                <p>In addition to working with the best linguists in the industry, our in-house multimedia team meets the demands of digital clients.</p> 
                <div><img src={top30low} alt="" style={{width: '10rem', marginLeft: '80px'}}/></div>       

              </div>
            </Col>   
            <Col size="md-4">  
            <div className="offVideo">
              <h2>Corporate Video</h2>
              <div className="video">
                <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='Uppx340TFtU' onClose={() => this.setState({isOpen: false})} /> 
                <img src={video} width="370" height="265" alt="video" className="cover" /> 
                <div className="playico"><img onClick={this.openModal} src={play} alt="" /></div> 
              </div>
              <h4 style={{marginTop: '20px', color: '#146bc0'}}>Awards & Memberships</h4>
              <div><img src={gsa} alt="" /><img src={ata} style={{marginTop: '20px'}} alt="" /></div>       
            </div>
            </Col>  
            <Col size="md-4">  
            <div className='offOffices'>
              <h2>Our Offices</h2>
              <div className="miami">
                <h4>Miami</h4>
                <p className="phone">+1 786-306-1924</p>
                <p className="address">2980 McFarlane Rd, Miami, FL 33133</p>
              </div>
              <div className="bsas">
                <h4>Buenos Aires</h4>
                <p className="phone">+5411 5273-5099</p>
                <p className="address">Av. Corrientes 1820. Ciudad de Bs. As.</p>
              </div>
              <h2>Contact</h2>
              <a style={{fontSize: '1.2em'}} href="mailto:info@mlginternational.net">info@mlginternational.net</a>
              {/* <div><img src={top30low} alt="" style={{width: '10rem', marginLeft: '80px'}}/></div>        */}

              {/* <ul className="orangecheck"> */}
                {/* <li><a title="Our Team" href="/our-team/">Meet the Team</a></li>
                <li><a title="Partnership" href="/our-team/partner/">Partnership Opportunities</a></li> */}
              {/* </ul>  */}
            </div>         
            </Col>  
          </Row>
        </Container>    
        </div>
    );
  }

}

export default Offices;
