import React, { Component } from "react";
import Dropzone from 'react-dropzone';
import axios from "axios";
require("dotenv").config();

class FileUpload extends Component {
 
  constructor(props) {
    super(props);
      this.state = {
        selectedFile: null
        // candName: props.candName
      }   
  }

  onDrop = uploadedFile => { 
    var day = new Date().toLocaleDateString(); 
    console.log("pressed")
    //sample script example
    var file = new Blob([uploadedFile[0]], {type: 'application/pdf'});
    var metadata = {
      'name': day + uploadedFile[0].name,
      'mimeType': 'appplication/pdf',
      'parents': ['1p88i0MudTZQFSKf6oU79sYqCb3YQJ0nr']
    }
    
    const dataCand = new FormData();
    // dataCand.append('file', uploadedFile[0]);
    dataCand.append('metadata', new Blob([JSON.stringify(metadata)], {type: 'application/json'}));
    dataCand.append('file', file);

    axios({
      url: 'https://accounts.google.com/o/oauth2/token?grant_type=refresh_token&refresh_token=' + process.env.REACT_APP_refreshToken + '&client_id=' + process.env.REACT_APP_clientID + '&client_secret=' + 
      process.env.REACT_APP_clientSecret,
      method: 'post'
    }).then(res=> {
        axios({
          // url:'https://www.googleapis.com/upload/drive/v3/files?uploadType=media',
          url: 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&fields=id',
          method:'post',
          data: dataCand,
          headers:{
              // 'Content-Type': 'application/pdf',
              // 'Content-Type': 'multipart/form-data;boundary=9700883396bond',
              'Authorization':"Bearer " + res.data.access_token,
              'name': 'cat.pdf'
          }  
        }).then(res =>console.log(res.status))
        .catch(err => res.status(422).json(err));
    })
  }

  render() {
    const maxSize = 5242880;
    return (
      <div className="text-center mt-2">
        <Dropzone 
          onDrop={this.onDrop}
          // accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
          accept="application/pdf"
          minSize={0}
          maxSize={maxSize}
        >
          {({getRootProps, getInputProps, rejectedFiles, acceptedFiles}) => {
            const isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;

            return (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <button>Need to attach a file?</button>
               <br></br>(Only .pdf please, less than 5MB and include your last name on file name)
              {isFileTooLarge && (
                <div className="text-danger mt-2">
                  File is too large.
                </div>                
              )}
              <ul className="list-group mt-2">
                {acceptedFiles.length > 0 && acceptedFiles.map(acceptedFile => 
                <li className="list-group-item list-group-item-success" key={0}>
                  Your file {acceptedFile.name} was successfully sent
                </li>
                )}
              </ul>
            </div>
          )}}
          
        </Dropzone>
      </div>
    )
  }
 
}

export default FileUpload;
