import React, { Component } from 'react';
import './style.css';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import test1 from '../../assets/testim1.png';
import test2 from '../../assets/testim2.png';
import test3 from '../../assets/testim3.png';
import testshort1 from '../../assets/testim1short.png';
import testshort2 from '../../assets/testim2short.png';
import testshort3 from '../../assets/testim3short.png';
import { Container } from '../Grid';

export default class Testimonials extends Component {
    

  render() {
    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
      };

    
        return (
            <div className="testim">
                <Container>
                <div className="bigSlider">
                <Slider {...settings}> 
                    <div className="slide-img"><img src={test1} alt="" height="289px"/></div>   
                    <div className="slide-img"><img src={test2} alt="" height="289px"/></div>
                    <div className="slide-img"><img src={test3} alt="" height="289px"/></div>
                </Slider> 
                </div>
                <div className="shortSlider">
                <Slider {...settings}>
                    <div className="shortImg"><img src={testshort1} alt="" height="289px"/></div>   
                    <div className="shortImg"><img src={testshort2} alt="" height="289px"/></div>
                    <div className="shortImg"><img src={testshort3} alt="" height="289px"/></div>   
                </Slider>  
                </div>
                </Container>  
            </div>
        )

    }

  }
