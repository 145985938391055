import React, { Component } from "react";
import Nav from "../components/NewNav";
import FormPartner from "../components/FormPartner";
import { FooterFixed } from "../components/FooterFixed";

class Partner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            referrer: null
        }
    }
    render() {
        return(
            <div>
            <Nav />
            <FormPartner />
            <FooterFixed />
            </div>
        )
    }
}
export default Partner;