import React, { Component } from "react";
import { Container, Row } from "../Grid";
import './style.css';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import FileUpload from '../FileUpload';

class FormPartner extends Component {
  constructor(props) {
    super(props)
    this.state = {         
      name: "",
      email: "",     
      comments: ""
    }
  }
  handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    this.setState(
        { [name]: value},
    );
  }
  handleSubmit = e => {
    e.preventDefault();
    if(this.state.name === "" || this.state.email === "" || this.state.language === "") {

       const getAlert = () => (
        <SweetAlert 
        warning 
        title="Please fill out all required fields" 
        onConfirm={this.hideAlert} 
        >
        </SweetAlert>
      );
  
      this.setState({
          alert: getAlert()
      }); 
    } else {
      
      axios({
        method: "POST", 
        url:"/api/email/partner", 
        data: {
            name: this.state.name,   
            email: this.state.email,  
            text: this.state.comments
        }
      })
      .then((response)=>{
      console.log(response)
        if (response.data.msg === 'success'){
            const getAlert = () => (
              <SweetAlert 
              success 
              title="Thank you!" 
              onConfirm={this.hideAlert} 
              >
                We'll get back at you as soon as possible.
              </SweetAlert>
            );
            this.setState({
              alert: getAlert()
            }); 
        }else if(response.data.msg === 'fail'){
          const getAlert = () => (
            <SweetAlert 
            warning 
            title="Your message couldn't be sent." 
            onConfirm={this.hideAlert} 
            >
              Please, send us the message again. Thanks.  
            </SweetAlert>
          );
          this.setState({
            alert: getAlert()
          }); 
        }
      })  
    }   
  }

    hideAlert = () => {
      this.setState({
          alert: null,
          name: "",
          email: "",          
          comments: ""
      });
    }

  render() {

    return (
      <div className="partner">
        <Container>
          <h1><span>Hi,</span> contact us if you are interested in partnering with us!</h1>
          <div className="subtitle"><p>If you want to offer language solutions to your clients, please fill out the form below and someone will contact you with more information.</p></div>
          <Row>
            <div className="col-md-3"></div>
            <div className="col-md-6">
            <form>
              <div className="form-group">
                <label>Full Name *</label>
                <input type="text" className="form-control" name="name" value={this.state.name} onChange={this.handleChange}/>
              </div>  
              <div className="form-group">
                <label>E-mail *</label>
                <input type="text" className="form-control" name="email" value={this.state.email} onChange={this.handleChange}/>
              </div> 
              
              <div className="form-group">
                  <label>Comments</label>
                  <textarea className="form-control" name="comments" value={this.state.comments} onChange={this.handleChange} rows="4" />
              </div>
              <div className="">
                  <p>* Required fields</p>
              </div>
              <FileUpload id="pickfiles"/>
              <div className="form-button">
                <button id="btfreel" onClick={this.handleSubmit}>SEND</button>
              </div>
            </form>
            </div>
            <div className="col-md-3"></div>

          </Row>     
        </Container>
        {this.state.alert}
      </div>

    );
  }
}

export default FormPartner;
