import React from "react";
import Nav from "../components/NewNav";
import TermsAndCond from "../components/TermsAndCond";
// import Contact from "../components/Contact";
import { Footer } from "../components/Footer";
import Offices from "../components/Offices";

export function Terms() {
    
    return(
        <div>
        <Nav />
        <TermsAndCond />
        <Offices />
        {/* <Contact /> */}
        <Footer />
        </div>
    )
}