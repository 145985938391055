import React from "react";
import Nav from "../components/NewNav";
// import Contact from "../components/Contact";
import Offices from "../components/Offices";
import { ServBanner } from "../components/Services";
import { Translation } from "../components/Translation";
import { Graphics } from "../components/Graphics";
import { Audio } from "../components/Audio";
import { Marketing } from "../components/Marketing";
import { LearnMore } from "../components/LearnMore";
import { Footer } from "../components/Footer";

export function Services() {
    return(
        <div>
        <Nav />
        <ServBanner />
        <Translation />
        <Graphics />
        <Audio />
        <Marketing />
        <LearnMore />
        <Offices />
        <Footer />
        </div>
    )
}